import HttpClient from '@/utils/http'

export default {
    // 通过id查询单个课程信息
    getCourseDetails(id, data = {}) {
        return HttpClient.get(`/cloud-livestream/course/${id}`, data)
    },
    // 查询首页课程评价列表
    getEvalQueryHomeList(data = {}) {
        return HttpClient.get(`/cloud-livestream/evaluate/queryHomeList`, data)
    },
    // 查询学生直播列表(查课程下的直播)
    getQueryCourseLive(data) {
        return HttpClient.get(`/cloud-livestream/live/queryCourseLive`, data)
    },
    // 获取单个直播（直播页面）
    getLiveDetailsHom(id, data = {}) {
        return HttpClient.get(`/cloud-livestream/live/get/${id}`, data)
    },
    // 首页查询单个专题
    getSubjectIndex(id, data = {}) {
        return HttpClient.get(`/cloud-livestream/subject/index/${id}`, data)
    },
    // 查询门户首页专题详情下直播列表
    queryHomeLiveList(id, data = {}) {
        return HttpClient.get(`/cloud-livestream/subject/queryHomeLiveList/${id}`, data)
    },
    // 查询门户首页专题详情下课程列表
    queryHomeCourseList(id, data = {}) {
        return HttpClient.get(`/cloud-livestream/subject/queryHomeCourseList/${id}`, data)
    },
    // 学生看直播权限验证
    checkLiveAuth(id, data = {}) {
        return HttpClient.get(`/cloud-livestream/live/checkLiveAuth/${id}`, data)
    },
    // 验证直播密码
    checkLivePassword(data = {}) {
        return HttpClient.get(`/cloud-livestream/live/checkLivePassword`, data)
    },
    // 参与人数+1
    putAddJoin(data) {
        return HttpClient.get(`/cloud-livestream/statistics/live/add/join`, data)
    },
    // 回放人数+1
    addBackWatch(data) {
        return HttpClient.get(`/cloud-livestream/statistics/live/add/back/watch`, data)
    },
}
