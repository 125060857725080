export default {
    // TIM appId
    appId: '1400323866',
    // 页面类型
    pageType: {
        live: 'live', // 直播
        course: 'course', // 课程
        column: 'column' // 专题
    },
    // 角色类型
    identityType: {
        Teacher: "Teacher",
        Student: "Student"
    },
    // 角色名称
    identityName: {
        Teacher: "老师",
        Student: "学生"
    },
    // 直播状态
    liveStatus: {
        0: '未开始',
        1: '直播中',
        2: '已结束'
    },
    // 直播状态
    liveStatusKey: {
        ready: 0, // 未开始
        doing: 1, // 直播中
        end: 2    // 已结束
    },
    // 直播类型
    liveType: {
        0: '课堂直播',
        1: '会议直播',
        2: '活动直播'
    },
    // 直播类型
    liveTypeKey: {
        classroom: 0,
        meeting: 1,
        activity: 2,
        all: 10
    },
    // 直播时间段
    liveTimePeriod: {
        // 0: '本日',
        // 1: '本周',
        2: '本月',
        // 3: '本季度',
        4: '本年'
    },
    // 直播时间段
    liveTimePeriodKey: {
        day: 0,
        week: 1,
        month: 2,
        quarter: 3,
        year: 4
    },
    // 区分机构类型
    typeDiffer: {
        true: '本机构',
        false: '其他机构'
    },
    // 区分机构类型
    typeDifferKey: {
        our: 'true',
        other: 'false'
    },
    // 直播权限验证
    checkLive: {
        noCheck: '1', // 不验证
        checked: '0' // 验证
    }
}