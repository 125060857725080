import { render, staticRenderFns } from "./baseHeads.vue?vue&type=template&id=70181b1e&scoped=true&"
import script from "./baseHeads.vue?vue&type=script&lang=js&"
export * from "./baseHeads.vue?vue&type=script&lang=js&"
import style0 from "./baseHeads.vue?vue&type=style&index=0&id=70181b1e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70181b1e",
  null
  
)

export default component.exports