export default [
    {
        path: '/myTeLive',
        component: () => import('@/views/teacher/myTeLive'),
        meta: {title: '我的直播'}
    },
    {
        path: '/myTeCourse',
        component: () => import('@/views/teacher/myTeCourse'),
        meta: {title: '我的课程'}
    }
];
