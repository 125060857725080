<template>
    <div class="baseNoData">
        <img src="../../assets/images/noDate.png" height="108" width="136"/>
        <p>暂无数据</p>
    </div>
</template>
<script>
export default {
    computed: {},
    created: function () {
    },
    mounted: function () {
    },
    methods: {}
};
</script>
<style lang="less" scoped>
    .baseNoData {
        margin-top: 50px;
        margin-bottom: 50px;
        width: 100%;
        text-align: center;
        p {
            font-size: 28px;
            font-weight: 400;
            color: #CAD2DE;
            line-height: 42px;
        }
    }
</style>
