import router from './router'
import store from './store'
import {getToken} from '@/utils/auth'

const clearScroll = (fn) => {
    document.documentElement.scrollTop = 0;
    fn()
};
// 判断平台
const isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

    return flag;
}

router.beforeEach(async (to, from, next) => {
    if (isMobile()) {
        if (getToken()) { // token 存在获取用户信息
            const userInfokeys = Object.keys(store.state.users.userVo);

            if (userInfokeys.length > 0) {
                clearScroll(next);
            } else {
                try {
                    await store.dispatch('users/getInfo');
                    if (to.path === '/login') {
                        next('/');
                    } else {
                        clearScroll(next);
                    }
                } catch (error) {
                    console.log(error);
                    store.dispatch('users/resetToken');
                    next('/');
                }
            }
        } else {
            if (to.meta.logIn) {
                next('/');
            } else {
                clearScroll(next);
            }
        }
    } else {
        if (to.path.indexOf("/liveDetails") > -1 && to.query.id && to.query.courseId) {
            window.location.href = process.env.VUE_APP_PC + '/player/' + to.query.id + '/' + to.query.streamName + '/' + to.query.courseId
        } else if (to.path.indexOf("/courseDetails") > -1) {
            window.location.href = process.env.VUE_APP_PC + "/courseDetails/" + to.query.id
        } else {
            window.location.href = process.env.VUE_APP_PC
        }
    }
});