import {
    setToken,
    removeToken,
    setRefreshToken,
    removeRefreshToken
} from '@/utils/auth';
import api from '../../api/users';

const callback = (context, data) => {
    if (data) {
        // 设置token
        let accessToken = data.access_token;

        let refreshToken = data.refresh_token;

        setToken(accessToken);
        setRefreshToken(refreshToken);

        data.userVo.roleList = data.userVo.roleList.filter(item => ['Teacher', 'Student'].includes(item));
        context.commit('setUserVo', data.userVo);
        context.commit('setIdentity', data.userVo.roleList[0]);
    } else {
        // 删除token
        removeToken();
        removeRefreshToken();
        context.commit('setUserVo', {});
        context.commit('setIdentity', '');
    }

};

const views = {
    namespaced: true,
    state: {
        identity: '', // 用户角色
        userVo: {} // 用户信息
    },
    mutations: {
        setIdentity(state, data) {
            state.identity = data
        },
        setUserVo(state, data) {
            state.userVo = data;
        }
    },
    actions: {
        changeIdentity(context, data) {
            context.commit('setIdentity', data);
        },
        changeUersDate(context, data) {
            callback(context, data);
        },
        getInfo(context) {
            return new Promise((resolve, reject) => {
                api.getInfo().then(response => {
                    response.roleList = response.roleList.filter(item => ['Teacher', 'Student'].includes(item));
                    context.commit('setUserVo', response);
                    context.commit('setIdentity', response.roleList[0]);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        refreshToken(context, refreshToken) {
            return new Promise((resolve, reject) => {
                api.refreshToken({
                    refresh_token: refreshToken
                }).then(response => {
                    setToken(response.access_token);
                    setRefreshToken(response.refresh_token);
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        resetToken(context) {
            return new Promise(resolve => {
                callback(context);
                resolve()
            })
        },
        logout(context) {
            return new Promise((resolve, reject) => {
                api.loginOut().then(response => {
                    callback(context);
                    window.location.reload()
                    resolve(response);
                }).catch(error => {
                    reject(error)
                });
            })
        }
    }
};

export default views
