/* eslint-disable indent,no-inner-declarations */
import http from '../../utils/http.js';
import TIM from 'tim-js-sdk';
import store from './../index.js'
import COS from "cos-js-sdk-v5";
import constant from '@/utils/constant';

const views = {
    namespaced: true,
    state: {
        appId: constant.appId,
        userId: '', // 用户id
        userSign: '', // 用户登录认证
        tim: {}, // TIM 对象
        groupID: '', // @TGS#aCZ5B6XGY, @TGS#2ZJAQ5XGK （频道id）
        liveId: '', // 直播id
        nextReqMessageID: null, // 用于分页续拉的消息 ID。第一次拉取时该字段可不填，每次调用该接口会返回该字段，续拉时将返回字段填入即可。
        messageList: [], // 消息列表
        isManageRole: false, // 是否为管理员
        ready: false, // 是否准备就绪
        joinGroup: '', // 会话类型，取值TIM.TYPES.CONV_C2C（端到端会话） 或 TIM.TYPES.CONV_GROUP（群组会话）
        newNotice: 0,  // 消息条数递增
        onlineUsers: 0, // 在线人数
        isOnline: false
    },
    mutations: {
        SET_TIM(state, tim) {
            state.tim = tim;
        },
        SET_USERID(state, userId) {
            state.userId = userId;
        },
        SET_LIVEID(state, liveId) {
            state.liveId = liveId;
        },
        SET_ONLINEUSERS(state, onlineUsers) {
            state.onlineUsers = onlineUsers;
        },
        SET_GROUPID(state, groupID) {
            state.groupID = groupID;
        },
        SET_NEWNOTICE(state) {
            state.newNotice += 1;
        },
        SET_JOINGROUP(state, joinGroup) {
            state.joinGroup = joinGroup;
        },
        SET_NEXT_REQMESSAGEID(state, nextReqMessageID) {
            state.nextReqMessageID = nextReqMessageID;
        },
        SET_ISMANAGEROLE(state, isManageRole) {
            state.isManageRole = isManageRole;
        },
        SET_TIM_READY(state, ready) {
            state.ready = ready;
        },
        SET_ISONLINE(state, isOnline) {
            state.isOnline = isOnline;
        },
        CLEAR_TIM_MESSAGES(state) {
            state.messageList = [];
        },
        SET_TIM_MESSAGES(state, messageList) {
            state.messageList.unshift(...messageList);
        },
        PUSH_TIM_MESSAGES(state, data) {
            state.messageList.push(data);
        }
    },
    actions: {
        // 初始化
        initTIM: async function ({state, commit, dispatch}, obj) {
            const userId = store.state.users.userVo.openId || `s${new Date().getTime()}`;

            if (state.groupID !== obj.groupID) {
                if (state.isOnline) {
                    if (state.tim.quitGroup) {
                        await state.tim.quitGroup(state.groupID);
                        state.tim.off(TIM.EVENT.SDK_READY, store.readyTIM);
                        state.tim.off(TIM.EVENT.MESSAGE_RECEIVED, store.messageTIM);
                    }
                    if (state.tim.logout) {
                        await state.tim.logout();
                    }
                    commit('SET_ISONLINE', false);
                    commit('SET_TIM', {});
                }
                commit('CLEAR_TIM_MESSAGES');
                commit('SET_GROUPID', obj.groupID);
                commit('SET_LIVEID', obj.liveId);
                commit('SET_USERID', userId);
                http.get('/cloud-livestream/tim/im/sign', {userId: userId}).then((res) => {
                    // init TIM client
                    let tim = TIM.create({SDKAppID: state.appId});

                    commit('SET_TIM', tim);
                    tim.setLogLevel(1);
                    commit('SET_JOINGROUP', TIM.TYPES.CONV_GROUP);

                    // 定义准备成功回调
                    store.readyTIM = function GGGG() {
                        commit('SET_TIM_READY', true);
                        console.log('初始化成功');
                        // 更新个人资料
                        let profile = {
                            nick: store.state.users.userVo.loginName
                        };

                        if (store.state.users.userVo.avatar) {
                            profile.avatar = store.state.users.userVo.avatar;
                        }
                        tim.updateMyProfile(profile).then(() => {
                            dispatch('joinGroup', obj);
                        });
                    };
                    // on ready
                    tim.on(TIM.EVENT.SDK_READY, store.readyTIM);

                    tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, (resx) => {
                    });

                    // 定义准备成功回调
                    store.messageTIM = async function KKKK({data}) {
                        console.log(data[0], '接收');
                        switch (data[0].payload.operationType) {
                            // 群成员资料变更，例如：群成员被禁言
                            case TIM.TYPES.GRP_TIP_MBR_PROFILE_UPDATED:
                                break;
                            // 有成员加群
                            case TIM.TYPES.GRP_TIP_MBR_JOIN:
                                dispatch('getGroupProfile', obj.groupID);
                                break;
                            // 有群成员退群
                            case TIM.TYPES.GRP_TIP_MBR_QUIT:
                                dispatch('getGroupProfile', obj.groupID);
                                break;
                            // 有群成员被踢出群
                            case TIM.TYPES.GRP_TIP_MBR_KICKED_OUT:
                                dispatch('getGroupProfile', obj.groupID);
                                break;
                            // 群组资料变更
                            case TIM.TYPES.GRP_TIP_GRP_PROFILE_UPDATED:
                                break;
                            // 自定义消息
                            case 255:
                                if (data[0].payload.userDefinedField) {
                                    let arrs = data[0].payload.userDefinedField.split('&');

                                    arrs = new Map(arrs.map(item => item.split('=')));
                                    if (arrs.get('type') === '1') { // 禁言设置
                                        // status: 0-取消禁言 1-禁言
                                        let isForbidden = arrs.get('status') === '1';

                                        if (arrs.get('user')) { // 禁言单个成员
                                            let memberList = await dispatch('getGroupMemberList', obj.groupID);

                                            memberList = memberList.filter(item => item.userID === arrs.get('user'));
                                            data[0].payload.systemMessage = isForbidden ? `${memberList[0].nick}被禁言` : `取消${memberList[0].nick}禁言`;
                                            if (userId === arrs.get('user')) {
                                                isForbidden ? commit('SET_TIM_READY', false) : commit('SET_TIM_READY', true);
                                            }
                                        } else { // 全体禁言
                                            data[0].payload.systemMessage = isForbidden ? '全体禁言' : '取消禁言';
                                            isForbidden ? commit('SET_TIM_READY', false) : commit('SET_TIM_READY', true);
                                        }
                                    } else if (arrs.get('type') === '2') { // 签到设置
                                        // status: 0-未开启签到 1-已开启签到 2-签到已结束
                                        commit('SET_SIGNFLAG', Number(arrs.get('status')));
                                    }
                                    commit('PUSH_TIM_MESSAGES', data[0]);
                                    commit('SET_NEWNOTICE');
                                }
                                break;
                            default:
                                commit('PUSH_TIM_MESSAGES', data[0]);
                                commit('SET_NEWNOTICE');
                                break;
                        }
                    };
                    // on message received
                    tim.on(TIM.EVENT.MESSAGE_RECEIVED, store.messageTIM);

                    // on error
                    tim.on(TIM.EVENT.ERROR, () => {
                        commit('SET_TIM_READY', false);
                        console.log('初始化失败');
                    });

                    tim.registerPlugin({'cos-js-sdk': COS});

                    tim.login({
                        userID: userId,
                        userSig: res.userSign
                    }).then(() => {
                        commit('SET_ISONLINE', true);
                    });
                });
            }
        },
        // 加入群聊
        joinGroup({state, commit, dispatch}, obj) {
            console.log('加入群聊');
            // 加入群聊
            state.tim.joinGroup({
                groupID: obj.groupID,
                type: TIM.TYPES.GRP_PUBLIC,
                applyMessage: store.state.users.userVo.loginName
            }).then(() => {
                // 查询当前角色是否为群主
                dispatch('getGroupMemberProfile');
                // 查询群资料
                dispatch('getGroupProfile', obj.groupID).then(res => {
                    // 后台统计最高峰值人数
                    http.get('/cloud-livestream/statistics/live/max/online', {
                        liveId: state.liveId,
                        maxOnlineNumber: res
                    }).then(() => {
                        console.log('发送最高峰值人数成功');
                    });
                });
            }).catch(() => {
                commit('SET_TIM_READY', false);
            });
        },
        // 查询当前用户在群组中的资料
        getGroupMemberProfile({state, commit}) {
            state.tim.getGroupMemberProfile({
                groupID: state.groupID,
                userIDList: [state.userId]
            }).then(resp => {
                let managerRoles = [TIM.TYPES.GRP_MBR_ROLE_OWNER, TIM.TYPES.GRP_MBR_ROLE_ADMIN];

                let isManageRole = managerRoles.includes(resp.data.memberList[0] && resp.data.memberList[0].role);

                commit('SET_ISMANAGEROLE', isManageRole);
            });
        },
        // 发送消息
        sendMessage({state, commit}, obj) {
            let message;

            let objs = {
                to: state.groupID,
                conversationType: state.joinGroup
            };

            if (!Object.keys(state.tim).length) {
                return;
            }
            switch (obj.type) {
                case TIM.TYPES.MSG_CUSTOM:
                    message = state.tim.createCustomMessage({
                        payload: {
                            data: obj.data,
                            description: '',
                            extension: ''
                        },
                        ...objs
                    });
                    break;
                case TIM.TYPES.MSG_IMAGE:
                    message = state.tim.createImageMessage({
                        payload: {
                            file: obj.file
                        },
                        ...objs
                    });
                    break;
                case TIM.TYPES.MSG_TEXT:
                    message = state.tim.createTextMessage({
                        payload: {
                            text: obj.text
                        },
                        ...objs
                    });
                    break;
                default:
                    break;
            }


            message.avatar = store.state.users.userVo.avatar;
            message.nick = store.state.users.userVo.loginName;

            // 2. 发送消息
            let promise = state.tim.sendMessage(message);

            promise.then(function (imResponse) {
                // 发送成功
                console.log(imResponse.data.message, '发送');
                commit('PUSH_TIM_MESSAGES', imResponse.data.message);
                commit('SET_NEWNOTICE');

                // 互动数+1
                http.get('/cloud-livestream/statistics/live/add/interact', {
                    liveId: state.liveId
                });
            }).catch(function (imError) {
                // 发送失败
                console.warn('sendMessage error:', imError);
            });
        },
        // 获取群成员列表
        getGroupMemberList({state, commit}, groupID) {
            return new Promise((resolve, reject) => {
                state.tim.getGroupMemberList({
                    groupID: groupID,
                    count: 30,
                    offset: 0
                }).then(function (imResponse) {
                    // 群成员列表
                    resolve(imResponse.data.memberList);
                }).catch(function (imError) {
                    // console.warn('getGroupMemberList error:', imError);
                    reject(imError);
                });
            });
        },
        // 获取群成员总数
        getGroupProfile({state, commit}, groupID) {
            return new Promise((resolve, reject) => {
                state.tim.getGroupProfile({
                    groupID: groupID,
                    groupCustomFieldFilter: [TIM.TYPES.GRP_PROFILE_MEMBER_NUM]
                }).then(function (imResponse) {
                    commit('SET_ONLINEUSERS', imResponse.data.group.memberNum);
                    resolve(imResponse.data.group.memberNum);
                }).catch(function (imError) {
                    // 获取群详细资料失败的相关信息
                    console.warn('getGroupProfile error:', imError);
                    reject(imError);
                });
            });
        },
        // 获取消息列表
        getMessageList({state, commit}) {
            return new Promise((resolve, reject) => {
                state.tim.getMessageList({
                    conversationID: `GROUP${state.groupID}`,
                    nextReqMessageID: state.nextReqMessageID,
                    count: 15
                }).then(function (imResponse) {
                    // 消息列表。
                    const messageList = imResponse.data.messageList;
                    // 用于续拉，分页续拉时需传入该字段。
                    const nextReqMessageID = imResponse.data.nextReqMessageID;
                    // 表示是否已经拉完所有消息。
                    const isCompleted = imResponse.data.isCompleted;

                    if (!isCompleted) {
                        commit('SET_NEXT_REQMESSAGEID', nextReqMessageID);
                    } else {
                        commit('SET_NEXT_REQMESSAGEID', '');
                    }
                    commit('SET_TIM_MESSAGES', messageList);
                    resolve();
                });
            })
        },
        // 清屏
        clearTimMessages({state, commit}) {
            commit('CLEAR_TIM_MESSAGES');
            commit('SET_NEXT_REQMESSAGEID', null);
        },
        // 禁言
        updateGroupProfile({state, commit, dispatch}, value) {
            state.tim.updateGroupProfile({
                groupID: state.groupID,
                muteAllMembers: value
            }).then(function (imResponse) {
                console.log(imResponse);
                // 修改成功后的群组详细资料
                dispatch('sendMessage', {
                    type: TIM.TYPES.MSG_CUSTOM,
                    data: value ? '禁言中...' : '取消禁言'
                });
            }).catch(function (imError) {
                console.warn('updateGroupProfile error:', imError);
            });
        },
        // 清除全部TIM/消息
        clearTIM: async function ({state, commit, dispatch}) {
            if (state.tim.quitGroup) {
                await state.tim.quitGroup(state.groupID);
                state.tim.off(TIM.EVENT.SDK_READY, store.readyTIM);
                state.tim.off(TIM.EVENT.MESSAGE_RECEIVED, store.messageTIM);
            }
            if (state.tim.logout) {
                await state.tim.logout();
            }
            commit('SET_GROUPID', '');
            commit('SET_TIM', {});
            commit('CLEAR_TIM_MESSAGES');
            commit('SET_NEXT_REQMESSAGEID', null);
            commit('SET_ONLINEUSERS', 0);
            commit('SET_TIM_READY', false);
            console.log('退出TIM');
        },
        // 派发签到
        updateSignflag({state, commit, dispatch}, value) {
            commit('SET_SIGNFLAG', value);
        }
    }
};

export default views
