import Cookies from 'js-cookie'

// token
const TokenKey = 'AccessToken';

// refresToken
const refreshTokenKey = 'RefresToken';


export function getToken () {
    return Cookies.get(TokenKey)
}

export function setToken (token) {
    return Cookies.set(TokenKey, token, {
        expires: 3,
        SameSite: 'Lax'
    })
}

export function removeToken () {
    return Cookies.remove(TokenKey)
}

// access
export function getRefreshToken () {
    return Cookies.get(refreshTokenKey)
}

export function setRefreshToken (token) {
    return Cookies.set(refreshTokenKey, token, {
        expires: 3,
        SameSite: 'Lax'
    })
}

export function removeRefreshToken () {
    return Cookies.remove(refreshTokenKey)
}
