<template>
  <div id="video" class="baseLive">
    <video
      v-if="videoSrc"
      class="video-js vjs-default-skin"
      webkit-playsinline="true"
      playsinline="true"
      style="width:100%;height:calc(100% - 0px)"
      id="refVideoJsItem"
      ref="refVideoJsItem"
      controls
      muted
      preload="auto">
          <source
              :src="videoSrc"
              type="application/x-mpegURL">
          </source>
    </video>
  </div>
</template>

<script>
import videojs from 'video.js/dist/video.js'
import 'videojs-contrib-hls'
import 'video.js/dist/video-js.css'
import api from '@/api/common';

export default {
  components: {},
  data() {
    return {
      player: null,
      videoSrc: '',
    }
  },
  props: {
    init: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    init(data) {
      this.initVideo(data)
    }
  },
  methods: {
    initVideo(data) {
      let videoObject = {
        container: '#video',
        variable: 'player',
        autoplay: false,
        mobileCkControls: false,
        live: data.status !== 2,
        video:
          data.status === 2 ? encodeURIComponent(data.videoUrl) : data.playUrl
      }
      if (data.playUrl.indexOf('m3u8') && data.status !== 2) {
        this.videoSrc = data.playUrl
        this.$nextTick(() => {
          videojs(
            'refVideoJsItem',
            {
              bigPlayButton: true,
              textTrackDisplay: false,
              // posterImage: true,
              // errorDisplay: false,
              controlBar: true
              // debug:true
            },
            function () {
              // this.play()
            }
          )
        })
      } else {
        this.player = new ckplayer(videoObject)
      }
      if (this.init.status === 2) {
          api.addBackWatch({ liveId: this.init.id });
      }
    }
  },
  created() {},
  mounted() {
    this.initVideo(this.init)
    this.$nextTick(() => {
        if (this.init.id) {
            api.putAddJoin({liveId: this.init.id});
        }
    })
  },
  activated() {}
}
</script>

<style lang='less' scoped>
.baseLive {
  width: 750px;
  height: 424px;
}
</style>