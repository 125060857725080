<template>
    <van-image lazy-load :src="url">
        <template v-slot:error>
            <img src="../../assets/images/couver_bg.png"/>
        </template>
    </van-image>
</template>

<script>
export default {
    name: "baseImage",
    props: ['url'],
    data () {
        return {};
    },
    methods: {},
    created () {
    },
    mounted () {
    },
    components: {},
    computed: {},
    watch: {}
};
</script>

<style scoped lang="less">
    .van-image, img{
        height: 100%;
        width: 100%;
    }
</style>
