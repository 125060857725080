/* eslint-disable indent */
import axios from 'axios'
import Promise from 'es6-promise'
import qs from 'qs'
import store from '../store/index'
import router from '../router'
import { getToken, getRefreshToken } from '@/utils/auth'
import { Dialog } from 'vant';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 50000;

let baseURL = '';

if (process.env.NODE_ENV !== 'development') {
    baseURL += process.env.VUE_APP_BASE_API;
}

const service = axios.create({
    // url = base url + request url
    baseURL: baseURL + process.env.VUE_APP_PROXY_PREFIX + '/service'
});

// http request 拦截器
service.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['access_token'] = getToken();
        }
        if (config.params && store.state.users.userVo.corpId) {
            if (!config.params.noCorp) {
                config.params.corpId = store.state.users.userVo.corpId;
            } else {
                delete config.params.noCorp
            }
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
);
// http response 拦截器
service.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    getToken() && store.dispatch('users/refreshToken', getRefreshToken()).then((res) => {
                        location.reload();
                    });
                    break;
                case 402:
                    store.dispatch('users/resetToken').then((err) => {
                        router.replace('/');
                    });
                    break;
                case 403:
                    break;
                case 500:
                    console.log(router.history.current.fullPath);
                    if (error.response.data.resultCode === 403) {
                        console.log(error.response.data);
                        Dialog.confirm({
                            message: '当前未登录，去登录？'
                        })
                            .then(() => {
                                router.push({
                                    path: '/login',
                                    query: {
                                        redirect: router.history.current.fullPath
                                    }
                                })
                            })
                            .catch(() => {
                                router.go(-1);
                            });
                    }
                    break;
                default:
                    break;
            }
        }
        return Promise.reject(error.response) // 返回接口返回的错误信息
    }
);

const checkStatus = function (response) {
    return new Promise((resolve, reject) => {
        if (response.status === 200) {
            if (response.data.resultCode === 0) {
                if (response.data.data === 'success') {
                    resolve();
                } else {
                    resolve(response.data.data)
                }
            } else {
                reject(response.data)
            }
        } else {
            reject(response.data)
        }
    })
}

export default {
    post (url, params) {
        return service({
            method: 'post',
            url,
            data: params,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(checkStatus)
    },
    postBody (url, data) {
        return service({
            method: 'post',
            url,
            data: qs.stringify(data),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        }).then(checkStatus)
    },
    get (url, params) {
        return service({
            method: 'get',
            url,
            params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(checkStatus)
    },
    put (url, params) {
        return service({
            method: 'put',
            url,
            params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(checkStatus)
    },
    delete (url, params) {
        return service({
            method: 'delete',
            url,
            params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then(checkStatus)
    }
}
