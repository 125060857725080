<template>
    <div></div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: {},
        computed: {},
        data() {
            return {
                socket: null,
                path: process.env.VUE_APP_HOST_SOCKET,
                liveId: '',
                initNum: 0 //重启次数
            }
        },
        methods: {
            init(liveId) {
                this.liveId = liveId
                if (typeof WebSocket === 'undefined') {
                    this.$message.error('您的浏览器不支持socket')
                } else {
                    if (this.socket) {
                        this.socket.close()
                    }
                    // 实例化socket
                    this.socket = new WebSocket(this.path + '?liveId=' + liveId)
                    // 监听socket连接
                    this.socket.onopen = this.open
                    // 监听socket错误信息
                    this.socket.onerror = this.error
                    // 监听socket消息
                    this.socket.onmessage = this.getMessage
                }
            },
            open() {
                console.log('socket连接成功')
            },
            error() {
                console.log('连接错误，正在重新连接')
                setTimeout(() => {
                    if (this.initNum < 40) {
                        this.initNum += 1
                        this.init(this.liveId)
                    }
                }, 2000)
            },
            getMessage(msg) {
                if (msg && /(^[1-9]\d*$)/.test(msg.data)) {
                    this.$emit('returnMsg', msg.data)
                }
            },
            close() {
                console.log('socket已经关闭')
                if (this.socket) {
                    this.socket.close()
                }
            }
        },
        destroyed() {
            // 销毁监听
            this.socket.onclose = this.close()
            this.initNum = 0
        },
        beforeDestroy() {
            this.socket.onclose = this.close()
            this.initNum = 0
        }
    }
</script>

<style scoped lang="less">
</style>
