export default [
    {
        path: '/myStLive',
        component: () => import('@/views/student/myStLive'),
        meta: {title: '我的直播'}
    },
    {
        path: '/myStCourse',
        component: () => import('@/views/student/myStCourse'),
        meta: {title: '我的课程'}
    }
];
