import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./module', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((moduless, modulePath) => {
    // set './app.js' => 'app'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = modulesFiles(modulePath);

    moduless[moduleName] = value.default;
    return moduless
}, {});

export default new Vuex.Store({
    modules,
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        reducer(value) {
            return {
                utils: value.utils
            }
        }
    })]
})
