import Vue from 'vue'
import VueRouter from 'vue-router'
import common from '../utils/common';

Vue.use(VueRouter);

const modulesFiles = require.context('./modules', false, /\.js$/);

let routes = [
    // {
    //     path: '/',
    //     redirect: '/home'
    // }
];

modulesFiles.keys().forEach(modulePath => {
    const value = modulesFiles(modulePath);

    if (Array.isArray(value.default)) {
        routes = value.default.concat(routes)
    } else {
        routes.unshift(value.default)
    }
});

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new VueRouter({
    mode: 'history',
    routes
});

const routerReplace = VueRouter.prototype.replace;

VueRouter.prototype.replace = function replace(location) {
    return routerReplace.call(this, location).catch(error=> error)
};


router.beforeEach((to, from, next) => {
    // document.title = to.meta.title;
    if (to.meta.title){
        common.setDocumentTitle(to.meta.title)
    }
    
    next()
});

export default router
