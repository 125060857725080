export default [
    {
        path: '/',
        component: () => import('@/views/home/index'),
        meta: {title: '云教室'}
    },
    {
        path: '/home',
        component: () => import('@/views/home/index'),
        meta: {title: '云教室'}
    },
    {
        path: '/livelist',
        component: () => import('@/views/home/liveList'),
        meta: {title: '直播列表'}
    },
    {
        path: '/wonReplay',
        component: () => import('@/views/home/wonReplay'),
        meta: {title: '精彩回放'}
    },
    {
        path: '/liveDetails',
        component: () => import('@/views/components/liveDetails'),
        meta: {title: ''}
    },
    {
        path: '/liveCourse',
        component: () => import('@/views/home/liveCourse'),
        meta: {title: '直播课程'}
    },
    {
        path: '/courseDetails',
        name: 'courseDetails',
        component: () => import('@/views/components/courseDetails'),
        meta: {title: '课程详情'}
    },
    {
        path: '/specialColumn',
        component: () => import('@/views/home/specialColumn'),
        meta: {title: '专题栏目'}
    },
    {
        path: '/columnDetails',
        component: () => import('@/views/components/columnDetails'),
        meta: {title: '专题详情'}
    }
];
