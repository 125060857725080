import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/common'
import moment from 'moment'
// 基础组件全局注册
import {autoLoadingGlobalComponent} from './assets/js/registered';
import './assets/less/index.less';
import Vant, { Lazyload } from 'vant';
import 'vant/lib/index.css';
import './permission';

Vue.use(Vant);
Vue.use(Lazyload);

// import {Icon, NavBar, Tabbar, TabbarItem, Button, Tab, Tabs, Swipe, SwipeItem} from 'vant'
//
// Vue.use(Icon).use(NavBar).use(Tabbar).use(TabbarItem).use(Button).use(Tab).use(Tabs).use(Swipe).use(SwipeItem);

autoLoadingGlobalComponent();
Vue.config.productionTip = false;
Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return moment(dataStr).format(pattern)
});

Vue.prototype.$moment = moment;
Vue.prototype.$utils = utils;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// new Vue({
//     el: '#app',
//     router,
//     store,
//     render: h => h(App),
// })
