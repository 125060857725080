import router from './../router';
import * as dd from 'dingtalk-jsapi'; 
export default {
    //深拷贝
    deepCopy(obj) {
        let result = Array.isArray(obj) ? [] : {};

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    result[key] = this.deepCopy(obj[key]);
                } else {
                    result[key] = obj[key];
                }
            }
        }
        return result;
    },
    // 判断浏览器环境
    browserEnvir() {
        let result = true;

        let ua = navigator.userAgent.toLowerCase();

        let browser = {
            weixin: 'micromessenger',
            qq: 'qq',
            dingding: 'dingtalk'
        };

        for (let key in browser) {
            if (ua.indexOf(browser[key]) > -1) {
                result = false;
            }
        }

        return result;
    },
    callMores(item, url, noCheck) {
        let query = {id: item.id};

        if (item.courseId) {
            query.courseId = item.courseId;
        }

        if (item.streamName) {
            query.streamName = item.streamName;
        }

        if (noCheck) {
            query.noCheck = noCheck;
        }
        router.push({path: url, query});
    },
    
    setDocumentTitle(title){
        document.title = title;
        let iframe = document.createElement('iframe');
        iframe.style.display = 'none'
        iframe.src = "https://cc.zjlll.net/"
        document.body.appendChild(iframe)
        iframe.addEventListener('load', function() {
            setTimeout(function() {
                iframe.remove()
            }, 0)
        })
        dd.ready(function() {
            dd.biz.navigation.setTitle({
                title: title, 
            })
        })
    }
}
