<template>
    <div class="baseContainer">
        <slot name="heads"/>
        <div class="contents">
            <slot/>
        </div>
        <slot name="foots"/>
    </div>
</template>

<script>


export default {
    name: 'base_hometitle',
    components: {},
    props: {
        config: {
            type: Object,
            default () {
                return {}
            }
        }
    },
    data () {
        return {};
    },
    methods: {},
    created () {
    },
    mounted () {
    },
    computed: {},
    watch: {}
}
</script>

<style scoped lang="less">
    .baseContainer {
        display: flex;
        flex-direction: column;
        height: 100vh;
        .contents {
            flex: 1;
            overflow-y: auto;
            background: #FCFCFC;
        }
    }
</style>
