export default [
    {
        path: '/personalSpace',
        component: () => import('@/views/my/personalSpace'),
        meta: {title: '个人中心', logIn: true}
    },
    {
        path: '/switchIdentities',
        component: () => import('@/views/my/switchIdentities'),
        meta: {title: '切换身份', logIn: true}
    }
];
