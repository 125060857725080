const views = {
    namespaced: true,
    state: {
        loading: false
    },
    mutations: {
        // loading
        setLoading(state, data) {
            state.loading = data
        }
    },
    actions: {
        changeLoading(context, data) {
            context.commit('setLoading', data);
        }
    }
};

export default views
