import HttpClient from '@/utils/http'

export default {
    // 登录
    goLogin (data) {
        return HttpClient.post('/cloud-centro/login/mobile', data)
    },
    // 用户信息
    getInfo (data) {
        return HttpClient.get(`/cloud-centro/centro/user/token`, data)
    },
    // 刷新登录
    refreshToken(data) {
        return HttpClient.get(`/cloud-centro/centro/user/refresh/token`, data)
    },
    // 登出
    loginOut(data = {}) {
        return HttpClient.post(`/cloud-centro/login/loginOut`, data)
    }
}
