<template>
    <div id="container-of-player" class="baseLive"></div>
</template>

<script>
import api from '@/api/common';

export default {
    name: "baseLive",
    props: {
        url: {
            type: String
        },
        type: {
            type: String,
            default: 'live'
        },
        liveDetail: {
            type: Object,
            default: ()=>{}
        }
    },
    data() {
        return {};
    },
    methods: {
        hanldeInt() {
            window.DHYPlayer.embed({
                type: this.type, // vod-点播, live-直播
                // metas: {
                //     playerId: "",
                //     serverHost: "http://player.danghongyun.com"
                // },
                video: {
                    url: this.url
                },
                player: {
                    elemId: "container-of-player",
                    type: this.type,
                    extras: {
                        // autoplay: false, // 自动播放
                        // noForwardingSeek: true // 代表拖拽的意思
                    }
                },
                advanced: {
                    render: 'native',
                    forceMSEInAndroid: false,
                    engines:{
                        xhls:{
                            manifestLoadingMaxRetry: 99999,
                            manifestLoadingRetryDelay: 2e3,
                            levelLoadingMaxRetry: 99999,
                            fragLoadingMaxRetry: 99999,
                            fragLoadingTimeOut: 3.6e5
                        }
                    }
                    // forceMSEInAndroid: true // 对安卓强制采用mse技术
                }
            }).then(player => {
                // do something to keep the player instance
                window._player = player;
                if (this.liveDetail.status === 2) {
                    api.addBackWatch({ liveId: this.liveDetail.id });
                }
            }).catch(error => {
                // do something to handle the error
                console.error(error);
            });
        }
    },
    created() {
    },
    mounted() {
        setTimeout(() => {
            this.hanldeInt();
            this.$nextTick(() => {
                if (this.liveDetail.id) {
                    api.putAddJoin({liveId: this.liveDetail.id});
                }
            })
        }, 1000);
    },
    beforeDestroy() {
        // window._player.destroy();
        window._player.pause();
    },
    components: {},
    computed: {},
    watch: {
        url(val) {
            console.log(val)
            this.hanldeInt();
        }
    }
};
</script>

<style scoped lang="less">
    .baseLive {
        width: 750px;
        height: 424px;
    }
    /deep/.xbtn-playrate{
        display: none;
    }
</style>
