<template>
    <div class="head_box">
        <van-nav-bar title="标题" left-text="返回" right-text="按钮" left-arrow style="height:45px;line-height:45px" />
    </div>
</template>

<script>
export default {
    name: "heads",
    data() {
        return {};
    },
    methods: {},
    created() {},
    mounted() {},
    components: {},
    computed: {},
    watch: {}
};
</script>

<style scoped lang="less">
.head_box{
    position: fixed;
    width: 100%;
}
</style>
